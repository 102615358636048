/*==================== GRAVATAR FUNC ====================*/
async function sha256(message) {
    const msgBuffer = new TextEncoder().encode(message);
    const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    return hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
}

async function getGravatarURL( email ) {
    const address = String( email ).trim().toLowerCase();
    let hash = await sha256(address)
    return `https://www.gravatar.com/avatar/${ hash }`;
}

async function apply_gravatar(){
    const dd_img = document.getElementById('img-gravatar');
    if (dd_img !== null && dd_img.dataset.email !== null) {
        dd_img.src = await getGravatarURL(dd_img.dataset.email);
    }
}

window.addEventListener("load", () => apply_gravatar(), false);